<script setup lang="ts">
import Icon from '@ui/components/icon.vue';
import { computed } from 'vue';

const emit = defineEmits(['showAdminPanel']);
const props = withDefaults(defineProps<{ openAdminPanel: boolean }>(), {});
const isOpen = computed({
    get: () => props.openAdminPanel,
    set: (value: boolean) => {
        emit('showAdminPanel', value);
    },
});
const toggle = () => {
    isOpen.value = !isOpen.value;
};
</script>
<template>
    <button
        class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-gray-900 hover:text-red-500"
        :class="isOpen ? 'bg-gray-900 text-red-500' : 'text-gray-400'"
        @click="toggle()"
    >
        <span
            class="relative flex h-5 w-5 transform items-center justify-center transition-transform duration-150 ease-out"
        >
            <Icon
                icon="far fa-shield-check"
                class="h-5 w-5 text-current"
                :class="isOpen ? 'opacity-0' : 'opacity-100'"
            ></Icon>
            <Icon
                icon="far fa-shield-xmark"
                class="absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2 text-current"
                :class="isOpen ? 'opacity-100' : 'opacity-0'"
            ></Icon>
        </span>
    </button>
</template>
