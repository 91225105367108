<script lang="ts" setup>
import MenuDropdown from '@app/components/parts/topBar/menuDropdown.vue';
import TabLink from '@app/components/parts/topBar/tabLink.vue';
import TabLinkIcon from '@app/components/parts/topBar/tabLinkIcon.vue';
import Dropdown from '@ui/components/dropdown.vue';
import { ref } from 'vue';

const props = withDefaults(
    defineProps<{
        tab: {
            text?: string;
            icon?: string;
            dropdown?: object;
        };
        menu: object;
    }>(),
    {
        tab: () => {
            return {};
        },
    },
);
const isOpen = ref(false);
function isActiveTab(tab) {
    return props.menu.active === tab.active;
}
function updateOpen(value) {
    isOpen.value = value;
}
</script>
<template>
    <li class="group flex cursor-pointer items-stretch">
        <Dropdown
            :is-open="isOpen"
            :distance="8"
            class="flex items-stretch"
            theme="dark"
            :arrow="false"
            :triggers="['click']"
            @update:is-open="
                (value) => {
                    updateOpen(value);
                }
            "
        >
            <TabLink
                v-if="props.tab.text"
                :tab="props.tab"
                :is-active="isActiveTab(props.tab)"
                :is-open="isOpen"
            ></TabLink>
            <TabLinkIcon
                v-if="props.tab.icon || props.tab.dropdown"
                :tab="props.tab"
                :is-open="isOpen"
            ></TabLinkIcon>

            <template #content>
                <MenuDropdown :menu="props.tab.dropdown"></MenuDropdown>
            </template>
        </Dropdown>
    </li>
</template>
