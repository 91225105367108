<script lang="ts" setup>
import { useFetchRecruitisApi2 } from '@/js/utils/useFetchApi';
import Notification from '@app/components/parts/topBar/notification.vue';
import LoadMore from '@app/components/loadMore.vue';
import Badge from '@ui/components/badge.vue';
import Dropdown from '@ui/components/dropdown.vue';
import Button from '@ui/components/forms/button.vue';
import Icon from '@ui/components/icon.vue';
import LoadingSpinner from '@ui/components/loadingSpinner.vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { flashMessage } from '@utils/flashMessage';
import { FlashMessageThemeEnum } from '@/js/enums/FlashMessageThemeEnum';
import { translate } from '@utils/I18n';

export interface TopBarNotificationsInterface {
    isMobile: boolean;
    listUrl?: string;
    countUrl?: string;
    allUrl?: string;
    seenUrl?: string;
    seenAllUrl?: string;
    limit?: number;
}

const props = withDefaults(defineProps<TopBarNotificationsInterface>(), {
    listUrl: '',
    countUrl: '',
    allUrl: '',
    seenUrl: '',
    seenAllUrl: '',
    limit: 50,
});

const emit = defineEmits(['toggle']);

const isOpen = ref(false);
const loadingCount = ref(true);
const count = ref();
const page = ref(1);
const totalOnPage = ref();
const loadingNotifications = ref(true);
const loadingNext = ref(false);
const notifications = ref([]);
const notificationsRefs = ref([]);
const scrollerRef = ref(null);
const fetchNotificationsCount = async () => {
    try {
        loadingCount.value = true;
        const { data } = await useFetchRecruitisApi2(props.countUrl).get().json();
        if (data.value) {
            count.value = data.value.payload.count;
        }
    } catch (error) {
        console.warn(error);
    } finally {
        loadingCount.value = false;
    }
};
const fetchNotifications = async () => {
    if (notifications.value.length > 0) {
        loadingNext.value = true;
    } else {
        loadingNotifications.value = true;
    }
    try {
        const { data } = await useFetchRecruitisApi2(`${props.listUrl}?limit=${props.limit}&page=${page.value}`)
            .get()
            .json();
        if (data.value) {
            const newNotifications = data.value.payload.map((notification) => ({
                ...notification,
                isVisible: true,
                isClickable: true,
            }));
            notifications.value.push(...newNotifications);
            await nextTick();
            if (newNotifications[0]?.id) {
                const element = notificationsRefs.value[newNotifications[0].id];
                scrollerRef.value.scroll(0, element.offsetTop);
            }
            totalOnPage.value = data.value.meta.entries_total;
        }
    } catch (error) {
        console.warn(error);
    } finally {
        loadingNotifications.value = false;
        loadingNext.value = false;
    }
};

const updateOpen = (value: boolean) => {
    emit('toggle', value);
    isOpen.value = value;
    notifications.value = [];
    page.value = 1;
};

const getSeenUrl = (id: number): string => {
    return props.seenUrl.replace(/%id%/g, id.toString());
};
const fetchSeen = async (id: number): Promise<void> => {
    try {
        await useFetchRecruitisApi2(getSeenUrl(id)).put().json();
    } catch (error) {
        console.warn(error);
    }
};
const fetchSeenAll = async (): Promise<void> => {
    const { error } = await useFetchRecruitisApi2(props.seenAllUrl).put().json();
    if (error.value) {
        flashMessage(FlashMessageThemeEnum.Error, error.value.meta?.message ?? translate('common.server_error'));
        console.error(error);
        return;
    }

    page.value = 1;
    count.value = 0;
    notifications.value = [];
};
const loadNextPage = () => {
    page.value = page.value + 1;
    fetchNotifications();
};
const hasNextPage = computed(() => {
    return props.limit * page.value < count.value;
});
const setNotificationRef = (el, id) => {
    notificationsRefs.value[id] = el;
    return el;
};

const onNotification = (event: Event, notification, redirect: boolean = true, blank: boolean = false) => {
    const notificationIndex = notifications.value.findIndex((n) => n.id === notification.id);
    if (notificationIndex === -1) {
        return;
    }

    const isNotificationClickable = notifications.value[notificationIndex].isClickable;

    if (!isNotificationClickable) {
        return;
    }

    if ((event.target as HTMLElement).tagName === 'A' || (event.target as HTMLElement).closest('a')) {
        event.preventDefault();
        const link =
            (event.target as HTMLElement).tagName === 'A'
                ? (event.target as HTMLElement)
                : (event.target as HTMLElement).closest('a');
        const href = link.getAttribute('href');
        window.open(href, '_blank');
        return;
    }

    notifications.value[notificationIndex].isClickable = false;
    notifications.value[notificationIndex].isVisible = false;

    if (props.seenUrl !== '') {
        fetchSeen(notification.id);
    }

    setTimeout(() => {
        count.value = count.value - 1;
        notifications.value[notificationIndex].isClickable = true;
        if (notification.url !== null && redirect) {
            if (blank) {
                window.open(notification.url, '_blank');
                return;
            }
            window.location.href = notification.url;
        }
    }, 150);
};

watch(isOpen, (newVal: boolean) => {
    if (newVal) {
        fetchNotifications();
    }
});
onMounted(() => {
    fetchNotificationsCount();
});
</script>
<template>
    <Dropdown
        :is-open="isOpen"
        :distance="props.isMobile ? 18 : 20"
        theme="dark"
        placement="top-end"
        :arrow="false"
        @update:is-open="
            (value) => {
                updateOpen(value);
            }
        "
    >
        <div
            class="hover:text-brand-400 relative flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full p-0 hover:bg-gray-900"
            :class="isOpen ? 'bg-gray-900 text-brand-400' : 'text-gray-400'"
        >
            <Icon
                icon="far fa-bell"
                class="pointer-events-none"
            ></Icon>
            <Badge
                size="xsmall"
                class="absolute right-0 top-0 min-h-5 -translate-y-1 translate-x-1 transition-all"
                :class="(count === 0 || typeof count === 'undefined') && !loadingCount ? 'scale-0' : ''"
                theme="dark-red"
                :outlined="false"
                circular
            >
                <div
                    class="absolute inset-0 flex items-center justify-center"
                    :class="loadingCount ? 'opacity-100' : 'pointer-events-none opacity-0'"
                >
                    <LoadingSpinner
                        size="small"
                        class="scale-75"
                    ></LoadingSpinner>
                </div>
                <div
                    class="grid overflow-hidden transition-all duration-150"
                    :class="loadingCount ? 'grid-cols-[0fr]' : 'grid-cols-[1fr]'"
                >
                    <div
                        class="overflow-hidden font-bold transition-all delay-75 duration-150"
                        :class="loadingCount ? '-translate-y-8 opacity-0' : 'translate-y-0 opacity-100'"
                    >
                        {{ count }}
                    </div>
                </div>
            </Badge>
        </div>

        <template #content>
            <div
                class="xl:w-128 relative flex h-64 w-64 transform-gpu overflow-hidden rounded bg-gray-800 font-sans text-sm text-white shadow-lg transition-all duration-150 ease-in-out sm:w-96 sm:origin-top-right lg:h-80 lg:!rounded-xl lg:text-base xl:h-96"
                style="
                    --primary-scroll: var(--tw-gray-600);
                    --secondary-scroll: var(--tw-gray-800);
                    --firefox-track-scroll: transparent;
                "
            >
                <div
                    :class="loadingNotifications ? 'opacity-100' : 'opacity-0'"
                    class="text-brand-500 pointer-events-none absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-gray-900 p-1 transition-opacity duration-150"
                >
                    <LoadingSpinner size="small"></LoadingSpinner>
                </div>
                <div
                    ref="scrollerRef"
                    :class="!loadingNotifications ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'"
                    class="customScrollbar relative flex w-full flex-col overflow-y-auto scroll-smooth pt-2 transition-all duration-150"
                >
                    <template v-if="notifications.length > 0 && count !== 0">
                        <div
                            v-for="n in notifications"
                            :key="n.id"
                            :ref="($event) => setNotificationRef($event, n.id)"
                        >
                            <Notification
                                :notification="n"
                                :seen-url="props.seenUrl"
                                @on-circle="(value) => onNotification(value.event, value.notification, false)"
                                @on-text="(value) => onNotification(value.event, value.notification)"
                                @on-text-middle="(value) => onNotification(value.event, value.notification, true, true)"
                                @on-icon="(value) => onNotification(value.event, value.notification, true, true)"
                            ></Notification>
                        </div>
                        <LoadMore
                            v-if="hasNextPage"
                            :loading="loadingNext"
                            @load-next="loadNextPage()"
                        ></LoadMore>
                        <div
                            class="sticky bottom-0 z-30 mt-auto w-full border-t border-gray-500/60 bg-gray-900/10 backdrop-blur-lg"
                        >
                            <div
                                class="relative z-10 flex w-full items-center justify-between py-2 pl-4 pr-2 text-right text-xs text-gray-500 md:text-sm"
                            >
                                <a
                                    class="m-0 pb-2 text-gray-400 md:pb-0"
                                    href="#"
                                    @click.prevent="fetchSeenAll"
                                >
                                    {{ $t("common.select_all") }}
                                </a>
                                <Button
                                    :href="props.allUrl"
                                    size="small"
                                    theme="primary"
                                >
                                    {{ $t("nav.notifications.all") }}
                                </Button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="flex h-full w-full flex-col items-center justify-center gap-y-8 text-gray-400">
                            <div class="inline-flex items-center gap-2 align-top">
                                <Icon icon="far fa-circle-check"></Icon>
                                <span>
                                    {{ $t("nav.notifications.none") }}
                                </span>
                            </div>
                            <Button
                                :href="props.allUrl"
                                size="small"
                                theme="primary"
                            >
                                {{ $t("nav.notifications.all") }}
                            </Button>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Dropdown>
</template>
