<script setup lang="ts">
import Icon from '@ui/components/icon.vue';
import { computed } from 'vue';

const emit = defineEmits(['update:isOpen']);
const props = withDefaults(
    defineProps<{
        open: boolean;
    }>(),
    {
        open: false,
    },
);
const isOpen = computed({
    get: () => {
        return props.open;
    },
    set: (val: boolean) => {
        emit('update:isOpen', val);
    },
});
function toggle() {
    emit('update:isOpen');
}
</script>
<template>
    <button
        type="button"
        class="mr-2 flex flex-shrink-0 items-center border-none bg-transparent p-0 focus:outline-none"
        @click="toggle"
    >
        <div
            class="hover:text-brand-400 flex h-10 w-10 cursor-pointer items-center justify-center rounded border-0 bg-transparent p-0 hover:bg-gray-900 focus:outline-none sm:block"
            :class="isOpen && 'bg-gray-900'"
        >
            <span
                class="flex h-10 w-10 transform items-center justify-center transition-transform duration-150 ease-out"
                :class="isOpen ? 'rotate-180 text-brand-400' : 'text-gray-400'"
            >
                <Icon
                    icon="far fa-bars"
                    class="h-5 w-5 text-current"
                    :class="!isOpen ? 'opacity-100' : 'opacity-0'"
                ></Icon>
                <Icon
                    icon="far fa-xmark"
                    class="absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2 text-current"
                    :class="isOpen ? 'opacity-100' : 'opacity-0'"
                ></Icon>
            </span>
        </div>
    </button>
</template>
