<script lang="ts" setup>
import { useFetchRecruitis } from '@/js/utils/useFetchApi';
import SearchResults from '@app/components/parts/topBar/searchResults.vue';
import Icon from '@ui/components/icon.vue';
import LoadingSpinner from '@ui/components/loadingSpinner.vue';
import { onClickOutside, refDebounced } from '@vueuse/core';
import { watch, ref, Ref, provide, computed } from 'vue';

export interface TopBarSearchLangInterface {
    placeholder?: string;
    resultAnswer?: string; //Uchazeč u
    resultCandidate?: string; //Uchazeč bez odpovědi
    resultDateAdd?: string; //inzerát přidán
}
export interface TopBarSearchInterface {
    isMobile?: boolean;
    openMobileSearch?: boolean;
    url: string;
    lang?: TopBarSearchLangInterface;
}

// TODO: solve ts error
// import { ResultInterface } from "@ui/components/parts/topBar/search/result.vue";
//const resultRefs: Ref<Ref<ResultInterface[]>[]> = ref([]);
const resultRefs: Ref<any[]> = ref([]);
provide('resultRefs', resultRefs);

const props = withDefaults(defineProps<TopBarSearchInterface>(), {
    lang: () => {
        return {};
    },
});
const emit = defineEmits(['hideMobileSearch']);

const isLoading = ref(false);
const hasResults = ref(false);
const resultsOpen = ref(false);
const searchQuery = ref('');
const searchQueryDebounced = refDebounced(searchQuery, 1000);
const results = ref([]);
const selectedIndex = ref(-1);
const inputRef = ref();
const searchWrapper = ref(null);
const hide = () => {
    resultsOpen.value = false;
};
const show = () => {
    if (hasResults.value) {
        resultsOpen.value = true;
    }
};
const hideMobileSearch = () => {
    resultsOpen.value = false;
    emit('hideMobileSearch');
};
const noData = () => {
    isLoading.value = false;
    hasResults.value = false;
    resultsOpen.value = false;
};
const t = computed(() => {
    return {
        placeholder: props.lang.placeholder,
        ...props.lang,
    };
});

const doSearch = async (query: string) => {
    if (searchQuery.value.length > 1) {
        try {
            isLoading.value = true;

            const { data } = await useFetchRecruitis(props.url + query)
                .get()
                .json();

            if (data.value.length > 0) {
                results.value = data.value;
                hasResults.value = true;
                resultsOpen.value = true;
            } else {
                noData();
            }
        } catch (error) {
            console.warn(error);
        } finally {
            isLoading.value = false;
        }
    } else {
        noData();
    }
};
const scrollToSelectedResult = () => {
    const selectedResult = resultRefs.value[selectedIndex.value];
    if (selectedResult) {
        selectedResult.$el.nextSibling.scrollIntoView({ block: 'nearest' });
    }
};
const handleKeydown = (event: KeyboardEvent) => {
    if (event.keyCode === 38 || (event.shiftKey && event.key === 'Tab')) {
        /* prev */
        event.preventDefault();
        if (selectedIndex.value === 0) {
            selectedIndex.value = results.value.length - 1;
        } else {
            selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
        }
        scrollToSelectedResult();
    } else if (event.keyCode === 40 || event.key === 'Tab') {
        /* next */
        event.preventDefault();
        if (selectedIndex.value < results.value.length - 1) {
            selectedIndex.value = Math.min(selectedIndex.value + 1, results.value.length - 1);
        } else {
            selectedIndex.value = 0;
        }
        scrollToSelectedResult();
    } else if (event.keyCode === 13) {
        if (typeof results.value[selectedIndex.value] !== 'undefined') {
            window.location = results.value[selectedIndex.value].link;
        } else {
            doSearch(searchQuery.value);
        }
    }
};
const setSelectedIndex = (index: number) => {
    selectedIndex.value = index;
};
onClickOutside(searchWrapper, () => {
    hide();
});
watch(
    () => props.openMobileSearch,
    (newVal: boolean) => {
        if (newVal === true) {
            inputRef.value.focus();
        }
    },
);
watch(
    () => props.isMobile,
    (newVal: boolean) => {
        if (newVal === true && !props.openMobileSearch) {
            hide();
        }
    },
);
watch(searchQueryDebounced, (newVal: string) => {
    doSearch(newVal);
});
</script>
<template>
    <div
        ref="searchWrapper"
        class="flex flex-grow flex-col items-stretch justify-center transition-transform duration-150 ease-in-out lg:translate-y-0"
    >
        <div class="mx-auto flex w-full items-stretch gap-2 lg:max-w-screen-sm">
            <div class="relative flex-grow">
                <input
                    ref="inputRef"
                    v-model="searchQuery"
                    type="search"
                    :placeholder="t.placeholder"
                    class="h-full min-h-10 w-full rounded border border-gray-700 bg-gray-900 py-2 pl-4 pr-12 font-sans text-base text-white ring-0 placeholder:text-ellipsis focus:border-gray-600 focus:outline-none"
                    @focus="show"
                    @click="show"
                    @keydown="handleKeydown"
                >
                <div
                    v-show="isLoading"
                    class="text-brand-500 absolute right-0 top-0 z-30 flex h-full w-10 items-center justify-center"
                >
                    <LoadingSpinner size="small"></LoadingSpinner>
                </div>
            </div>
            <button
                type="button"
                class="hover:text-brand-400 m-0 flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-transparent p-0 text-gray-400 hover:bg-gray-900 focus:outline-none lg:!hidden"
                @click="hideMobileSearch"
            >
                <Icon
                    icon="far fa-xmark"
                    class="h-5 w-5 text-current"
                ></Icon>
            </button>
        </div>

        <SearchResults
            :is-open="hasResults && resultsOpen"
            :results="results"
            :selected-index="selectedIndex"
            :lang="props.lang"
            @update:selected-index="
                (value) => {
                    setSelectedIndex(value);
                }
            "
        ></SearchResults>
    </div>
</template>
