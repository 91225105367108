import Sidebar from '@app/components/parts/sidebar.vue';
import { createApp } from 'vue';
import useI18n from '@/js/utils/I18n';

const has = (): boolean => {
    return document.querySelectorAll('[data-side-bar]').length > 0;
};

const start = async () => {
    const elements = document.querySelectorAll<HTMLElement>('[data-side-bar]');
    for (const el of Array.from(elements)) {
        const props = JSON.parse(el.dataset.props);
        createApp(Sidebar, {
            companyPhoto: props.companyPhoto,
            companyName: props.companyName,
            canHomepageFeedback: props.canHomepageFeedback,
            profilePictureUrl: props.profilePictureUrl,
            profileUrl: props.profileUrl,
            columns: props.columns,
        })
            .use(await useI18n())
            .mount(el);
    }
};
export const init = () => {
    if (has()) {
        start();
    }
};
