<script lang="ts" setup>
import { formatDate } from '@/js/utils/common';
import Avatar from '@ui/components/avatar.vue';
import Icon from '@ui/components/icon.vue';
import { TopBarSearchLangInterface } from '../search.vue';
import { grayLine } from '@/js/utils/tailwind';

export interface ResultItemInterface {
    id: number;
    type: string;
    link: string;
    title: string;
    applicant_avatar: string | null;
    fullname?: string;
    isActive?: number;
    offer_title: string;
    public_id?: string;
    date_created: {
        date: string;
    };
    date_created_origin: {
        date: string;
    };
    flow?: string;
}
export interface ResulInterface {
    result: ResultItemInterface;
    selectedIndex: number;
    index: number;
    lang: TopBarSearchLangInterface;
}

const props = withDefaults(defineProps<ResulInterface>(), {});
const getType = (type: string) => {
    return props.result.type === type;
};
const hasAvatar = props.result.applicant_avatar !== null;
</script>
<template>
    <a
        :href="result.link"
        class="flex w-full min-w-0 cursor-pointer flex-row flex-nowrap items-center gap-4 overflow-hidden px-4 py-2 no-underline lg:py-3"
        :class="
            [
                selectedIndex === index ? 'bg-brand-500 text-white' : 'text-gray-600',
                typeof result.isActive !== 'undefined' && !result.isActive && 'opacity-60 grayscale',
            ].join(' ')
        "
    >
        <Avatar
            v-if="hasAvatar"
            :image="result.applicant_avatar"
            class="flex-shrink-0 bg-white"
        ></Avatar>
        <div
            v-else
            class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border bg-white"
            :class="selectedIndex === index ? 'border-brand-500 text-brand-500' : 'border-gray-200 text-gray-400'"
        >
            <Icon :icon="result.type === 'tag' ? 'far fa-tag' : 'far fa-briefcase'"></Icon>
        </div>
        <div class="flex min-w-0 flex-shrink flex-grow flex-wrap">
            <div class="w-full text-base font-semibold tracking-tight">{{ result.title }}</div>
            <div
                class="w-full min-w-0 truncate text-sm"
                :class="selectedIndex === index ? 'text-brand-50' : 'text-gray-400'"
            >
                <template v-if="getType('answer')">
                    <div class="flex flex-wrap items-center gap-2">
                        <div :class="grayLine">{{ props.lang.resultAnswer }}: {{ result.offer_title }}</div>
                        <div
                            v-if="result.flow"
                            :class="grayLine"
                        >
                            {{ $t("search.result.flow") }}: {{ result.flow }}
                        </div>
                    </div>
                </template>
                <template v-if="getType('tag')">
                    {{ $t("search.result.tag_crated_at") }}:
                    {{ formatDate(result.date_created.date, "dd.MM.yyyy") }}</template>
                <template v-if="getType('candidate')">{{ props.lang.resultCandidate }}</template>
                <template v-if="getType('job')">
                    {{ $t("search.result.job_id") }}: {{ result.public_id }}, {{ props.lang.resultDateAdd }}:
                    {{ formatDate(result.date_created_origin.date, "dd.MM.yyyy") }}
                </template>
            </div>
        </div>
    </a>
    <!-- <div class="flex-grow font-medium">
        {{ result.title }} <span v-if="result.entries">({{ result.entries }})</span>
    </div>
    <div
        class="flex items-center gap-2 text-sm text-black"
        :class="selectedIndex === index ? 'opacity-80' : 'opacity-50'"
    >
        <div
            v-if="typeof result.isActive !== 'undefined' && !result.isActive"
            class="rounded-lg bg-gray-800 px-1.5 text-xs text-white"
        >
            Neaktivní
        </div>
        <div v-if="result.offer_title">Uchazeč u: {{ result.offer_title }}</div>
        <div v-if="result.date_created">Tag založen: {{ formatDate(result.date_created.date) }}</div>
        <div v-if="result.date_created_origin && !result.offer_title">
            Inzerát přidán: {{ formatDate(result.date_created_origin.date) }}
        </div>
    </div> -->
</template>
