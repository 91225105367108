<script setup lang="ts">
import { getBgColorByString } from '@/js/utils/common';
import Icon from '@ui/components/icon.vue';

const props = withDefaults(
    defineProps<{
        menu: object;
        inDropDown: boolean;
    }>(),
    {
        menu: () => {
            return {};
        },
        inDropDown: true,
    },
);

function hasCount(count: number) {
    return typeof count !== 'undefined';
}
</script>
<template>
    <div
        class="columns-1 p-2 text-sm text-white sm:pt-0 lg:origin-top-left lg:px-3 lg:pb-3 lg:text-[13px] xl:text-base"
        :class="
            [
                props.menu.columns.length > 1 && 'w-full sm:w-128 sm:columns-2',
                props.menu.columns.length > 2 && 'w-full lg:w-[1000px] lg:columns-3 xl:w-[1050px]',
            ].join(' ')
        "
    >
        <div
            v-for="(column, index) in props.menu.columns"
            :key="index"
            class="inline-flex w-full flex-col align-top"
            :class="column.header && 'lg:-mt-1'"
        >
            <h2
                v-if="column.header"
                class="font-brand -mb-1 mt-2 flex items-center px-3 text-base font-semibold leading-tight text-white sm:mt-4 lg:mt-6 xl:text-lg"
            >
                {{ column.header }}
            </h2>
            <ul
                class="m-0 list-none p-0 text-left"
                :class="column.header ? 'mt-3' : 'sm:pt-2 lg:pt-3'"
            >
                <li
                    v-for="(menuLink, idx) in column.items"
                    :key="idx"
                >
                    <a
                        v-if="menuLink.type !== 'divider'"
                        :href="menuLink.url"
                        class="group/link flex items-center gap-2 rounded px-3 py-1.5 font-sans text-gray-400 no-underline hover:bg-gray-900 hover:text-white lg:px-4 lg:py-2.5"
                    >
                        <Icon
                            v-if="menuLink.icon"
                            :icon="menuLink.icon"
                            size="xsmall"
                            class="group-hover/link:text-brand-400 -ml-1 flex-shrink-0 text-gray-400 lg:-ml-2"
                        ></Icon>
                        <span class="flex-grow">{{ menuLink.text }}</span>
                        <div
                            v-if="hasCount(menuLink.count)"
                            class="font-brand -my-2 flex h-6 min-w-[24px] items-center justify-center rounded-3xl px-1 text-xs font-bold text-white"
                            :class="getBgColorByString(menuLink)"
                        >
                            {{ menuLink.count }}
                        </div>
                    </a>
                    <div
                        v-else
                        class="-mx-2 my-2 flex h-px bg-gray-600 lg:-mx-3 lg:my-3"
                    ></div>
                </li>
            </ul>
        </div>
    </div>
</template>
