<script setup lang="ts">
import { siteUrl } from '@/js/utils/url';
import { useFetchRecruitisApi2 } from '@/js/utils/useFetchApi';
import Avatar from '@ui/components/avatar.vue';
import MultiSelect from '@ui/components/forms/multiSelect.vue';
import { useDebounceFn } from '@vueuse/core';
import { ref } from 'vue';

export interface TopBarAdminPanel {
    open?: boolean;
    admin?: {
        substitudeUserName?: string;
        companyName?: string;
        companyId?: number;
        userName?: string;
        userId?: number;
        userRole?: string;
        links?: [
            {
                text: string;
                url: string;
            },
        ];
    };
}

type employeesMultiselect = { value: string; label: string; avatar: string; role: string };

const props = withDefaults(defineProps<TopBarAdminPanel>(), {
    open: false,
    admin: () => {
        return {};
    },
});
const overflowHidden = ref(false);
const getEmloyees = async (query: string) => {
    try {
        const { data } = await useFetchRecruitisApi2('/employees?search=' + query)
            .get()
            .json();
        return data.value.payload.map(
            (item: { id: string | number; name: string; surname: string; photo_url: string; role: string }) => {
                return {
                    value: item.id,
                    label: item.name + ' ' + item.surname,
                    avatar: item.photo_url ? item.photo_url : '',
                    role: item.role ? item.role : '',
                };
            },
        );
    } catch (error) {
        console.warn(error);
    }
};
const debouncedGetEmployees = useDebounceFn(getEmloyees, 500);
const onEmployee = (id: number) => {
    location.href = siteUrl('administrace/prihlaseni/companyemployee/' + id);
};
const getOptionProperty = (option: employeesMultiselect, property: keyof employeesMultiselect) => {
    return option[property];
};
</script>
<template>
    <div
        class="grid bg-gray-900 font-sans text-base text-gray-600 transition-all"
        :class="props.open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'"
    >
        <transition
            enter-active-class="transition duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            @before-enter="overflowHidden = true"
            @after-enter="overflowHidden = false"
            @before-leave="overflowHidden = true"
            @after-leave="overflowHidden = false"
        >
            <div
                v-show="props.open"
                :class="{ 'overflow-hidden': overflowHidden }"
            >
                <div class="flex flex-wrap items-center gap-2 border-b border-gray-700 p-4 lg:flex-nowrap">
                    <div class="flex w-full flex-grow flex-col flex-wrap gap-2 sm:flex-row sm:items-center">
                        <div class="mr-4 flex flex-col gap-1">
                            <div class="flex items-center">
                                <span class="min-w-28">Superadmin:</span><span class="text-white">{{ props.admin.substitudeUserName }}</span>
                            </div>
                            <div class="flex flex-col gap-1">
                                <div class="flex items-center">
                                    <span class="min-w-28">Firma:</span><span class="text-white">
                                        {{ props.admin.companyName }} ({{ props.admin.companyId }})</span>
                                </div>
                                <div class="flex items-center">
                                    <span class="min-w-28">Uživatel:</span><span class="text-white">
                                        {{ props.admin.userName }} ({{ props.admin.userId }},
                                        {{ props.admin.userRole }})</span>
                                </div>
                            </div>
                        </div>
                        <div class="min-w-56 flex-grow pr-4 text-gray-300 sm:self-end xl:max-w-72">
                            <MultiSelect
                                mode="single"
                                :can-clear="false"
                                :close-on-select="true"
                                :filter-results="false"
                                :min-chars="0"
                                :resolve-on-load="false"
                                :infinite="true"
                                :limit="10"
                                :clear-on-search="true"
                                :delay="0"
                                :searchable="true"
                                theme="dark"
                                :lang="{
                                    placeholder: 'Přihlásit za...',
                                }"
                                :options="
                                    async (query) => {
                                        return await debouncedGetEmployees(query);
                                    }
                                "
                                @update:initial-value="(value) => onEmployee(value)"
                                @open="
                                    (select$) => {
                                        if (select$.noOptions) {
                                            select$.resolveOptions();
                                        }
                                    }
                                "
                            >
                                <template #option="{ option }">
                                    <div class="flex flex-nowrap items-center gap-2">
                                        <Avatar
                                            :image="getOptionProperty(option, 'avatar')"
                                            size="small"
                                            class="flex-shrink-0"
                                        ></Avatar>
                                        <div class="flex flex-grow flex-col">
                                            <div class="flex-grow">
                                                {{ getOptionProperty(option, "label") }}
                                            </div>
                                            <div class="flex-grow text-sm text-gray-500">
                                                {{ getOptionProperty(option, "role") }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </MultiSelect>
                        </div>
                    </div>
                    <div
                        class="text-brand-500 -mx-4 flex flex-wrap items-center self-end lg:min-h-[40px] lg:flex-shrink-0"
                    >
                        <a
                            v-for="(link, index) in props.admin.links"
                            :key="index"
                            :href="link.url"
                            class="text-brand-500 border-r border-gray-700 px-4 py-0.5 text-sm underline last:border-0 hover:no-underline"
                        >{{ link.text }}</a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
