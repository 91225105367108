<script setup lang="ts">
import MenuDropdown from '@app/components/parts/topBar/menuDropdown.vue';
import Avatar from '@ui/components/avatar.vue';
import Dropdown from '@ui/components/dropdown.vue';
import Icon from '@ui/components/icon.vue';
import { ref } from 'vue';

export interface TopBarUserInterface {
    user?: {
        image?: string;
        name?: string;
        company?: string;
    };
    isMobile: boolean;
}
const emit = defineEmits(['show']);
const props = withDefaults(defineProps<TopBarUserInterface>(), {
    user: () => {
        return {};
    },
    isMobile: false,
});

const isOpen = ref(false);
function updateOpen(value) {
    if (value === true) {
        emit('show');
    }
    isOpen.value = value;
}
</script>
<template>
    <Dropdown
        :is-open="isOpen"
        :distance="props.isMobile ? 18 : 20"
        theme="dark"
        placement="top-end"
        :arrow="false"
        @update:is-open="
            (value) => {
                updateOpen(value);
            }
        "
    >
        <div class="flex items-center gap-2">
            <div
                class="before:bg-brand-500 relative before:absolute before:inset-0 before:scale-100 before:rounded-full before:transition-all"
            >
                <Avatar
                    :image="props.user.image"
                    :name="props.user.name"
                    class="relative z-10 transition-all"
                    :class="isOpen ? 'scale-90' : 'scale-100'"
                ></Avatar>
            </div>
            <div class="hidden min-w-0 max-w-40 flex-col font-sans leading-[18px] antialiased xl:!flex">
                <span
                    class="font-brand truncate text-base font-bold"
                    :class="isOpen ? 'text-brand-500' : 'text-white'"
                >{{ props.user.name }}</span>
                <span class="truncate text-sm text-gray-300">{{ props.user.company }}</span>
            </div>
            <Icon
                icon="far fa-chevron-down"
                size="xsmall"
                class="hidden transition-all xl:!inline-flex"
                :class="isOpen ? 'rotate-180 text-white' : ''"
            ></Icon>
        </div>
        <template #content>
            <div class="flex flex-col">
                <div class="!flex flex-col border-b border-gray-700 px-4 py-3 font-sans xl:!hidden">
                    <span class="font-brand text-brand-500 text-base font-semibold">{{ props.user.name }}</span>
                    <span class="text-sm text-gray-300">{{ props.user.company }}</span>
                </div>
                <MenuDropdown
                    :menu="props.user"
                    :is-open="isOpen"
                    :in-drop-down="false"
                ></MenuDropdown>
            </div>
        </template>
    </Dropdown>
</template>
