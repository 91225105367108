<script lang="ts" setup>
import Layout from '@app/components/parts/sidebar/layout.vue';
import { useMediaQuery } from '@vueuse/core';
import { ref } from 'vue';

const props = withDefaults(
    defineProps<{
        companyPhoto?: string;
        canHomepageFeedback: boolean;
        profileUrl?: string;
        profilePictureUrl?: string;
        columns: Array<object>;
    }>(),
    {
        companyPhoto: '',
        profileUrl: '',
        profilePictureUrl: '',
        columns: () => {
            return [];
        },
    },
);

const isMobile = useMediaQuery('(max-width: 1023px)');
const openMobileSidebar = ref(false);
</script>
<template>
    <div class="block border-y border-gray-200 lg:divide-y-0 lg:border-y-0">
        <template v-if="isMobile">
            <div
                class="grid w-full transition-all duration-150 ease-in-out lg:grid-rows-1"
                :class="openMobileSidebar ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'"
            >
                <div class="overflow-hidden">
                    <Layout v-bind="props"></Layout>
                </div>
            </div>
            <button
                type="button"
                class="w-full cursor-pointer bg-transparent p-4 text-center font-bold focus:outline-none"
                @click="openMobileSidebar = !openMobileSidebar"
            >
                Přihlášený uživatel a společnost
            </button>
        </template>
        <Layout
            v-if="!isMobile"
            v-bind="props"
        ></Layout>
    </div>
</template>
