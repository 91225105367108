import '@/css/tailwind/styles.css';
import { init as initSidebar } from '@app/parts/sidebar';
import { init as initTopbar } from '@app/parts/topbar';
import { EventBus, Events } from '@/js/utils/EventBus';
import { loader } from '@/js/utils/common';

// To be used outside Vue
window.EventBus = EventBus;
window.Events = Events;
window.dispatchEvent(new CustomEvent('EventBusLoaded'));

loader(() => {
    initSidebar();
    initTopbar();
});
