<script lang="ts" setup>
import { getBgColorByString } from '@/js/utils/common';
import MenuDropdown from '@app/components/parts/topBar/menuDropdown.vue';
import Dropdown from '@ui/components/dropdown.vue';
import { ref } from 'vue';

const props = withDefaults(defineProps<{ data: { columns?: { items: { count: number } } }; isMobile: boolean }>(), {
    data: () => {
        return {};
    },
});
const emit = defineEmits(['show']);
const isOpen = ref(false);
function updateOpen(value) {
    if (value === true) {
        emit('show');
    }
    isOpen.value = value;
}
</script>
<template>
    <Dropdown
        class="ml-2 flex-shrink-0"
        theme="dark"
        :arrow="false"
        placement="top"
        :distance="props.isMobile ? 18 : 20"
        @update:is-open="
            (value) => {
                updateOpen(value);
            }
        "
    >
        <div class="flex h-10 flex-nowrap items-center">
            <div
                v-for="(level, index) in props.data.columns[0].items"
                :key="index"
                class="font-brand -ml-2 flex h-8 min-w-[32px] items-center justify-center rounded-3xl border-2 border-gray-800 px-1 text-sm font-bold text-white"
                :class="getBgColorByString(level)"
            >
                {{ level.count }}
            </div>
        </div>
        <template #content>
            <MenuDropdown
                :menu="props.data"
                class="w-48 md:w-80"
            ></MenuDropdown>
        </template>
    </Dropdown>
</template>
