<script lang="ts" setup>
import { ResultItemInterface } from '@app/components/parts/topBar/search/result.vue';
import ResultComponent from '@app/components/parts/topBar/search/result.vue';
import { inject, Ref } from 'vue';
import { TopBarSearchLangInterface } from './search.vue';

export interface TopBarSearchResultInterface {
    isOpen: boolean;
    selectedIndex?: number;
    results?: ResultItemInterface[];
    lang: TopBarSearchLangInterface;
}
export type ResultRefsType = Array<InstanceType<typeof ResultComponent>>;
const props = withDefaults(defineProps<TopBarSearchResultInterface>(), {
    selectedIndex: 0,
    results: () => {
        return [];
    },
});
const emit = defineEmits(['update:selectedIndex']);

const resultRefs = inject<Ref<Ref<ResultItemInterface[]>[]>>('resultRefs');
const setSelectedIndex = (index: number) => {
    emit('update:selectedIndex', index);
};
const getRef = (el, index) => {
    if (el) {
        resultRefs.value[index] = el;
    }
};
</script>
<template>
    <div class="pointer-events-none fixed inset-0 z-[200] font-sans">
        <div
            class="absolute left-1/2 top-14 z-[200] max-h-[50vh] w-full origin-top -translate-x-1/2 overflow-auto bg-white py-2 text-base shadow-lg transition duration-150 ease-in-out lg:top-12 lg:min-w-[550px] lg:max-w-screen-sm lg:rounded lg:text-sm xl:text-base"
            :class="
                [
                    props.isOpen
                        ? 'pointer-events-auto translate-y-0 opacity-100'
                        : 'pointer-events-none -translate-y-1 opacity-0',
                ].join(' ')
            "
        >
            <ResultComponent
                v-for="(result, index) in props.results"
                :ref="(el) => getRef(el, index)"
                :key="result.id"
                :result="result"
                :selected-index="selectedIndex"
                :index="index"
                :lang="props.lang"
                @mouseover="setSelectedIndex(index)"
            ></ResultComponent>
        </div>
    </div>
</template>
