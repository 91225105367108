<script setup lang="ts">
import MenuDropdown from '@app/components/parts/topBar/menuDropdown.vue';
import { isObjectEmpty } from '@/js/utils/common';
import { computed } from 'vue';
const props = withDefaults(
    defineProps<{
        open: boolean;
        menu: {
            active?: boolean;
            tabs?: [
                {
                    text: string;
                    url: string;
                    dropdown: object;
                },
            ];
        };
        sla?: {
            columns?: {
                header?: string;
                [key: string]: any;
            }[];
        };
    }>(),
    {
        menu: () => {
            return {};
        },
        sla: () => {
            return {};
        },
    },
);
function isActiveTab(section) {
    return props.menu.active === section.active;
}
function onSectionClick(e, section) {
    if (props.menu.active === section.active) {
        e.stopPropagation();
    }
}
const slaWithoutHeader = computed(() => {
    return {
        columns: props.sla.columns.map(({ header, ...rest }) => ({
            ...rest,
        })),
    };
});
</script>
<template>
    <div
        class="grid w-full transition-all duration-150 ease-in-out lg:grid-rows-1"
        :class="props.open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'"
    >
        <div class="overflow-hidden">
            <div
                class="xs:columns-2 w-full columns-1 border-t border-gray-700 bg-gray-800 pt-3 max-[768px]:columns-4 sm:columns-3"
            >
                <div
                    v-for="(section, index) in props.menu.tabs"
                    :key="index"
                    class="mb-3 inline-flex w-full flex-col"
                >
                    <component
                        :is="isActiveTab(section) ? 'strong' : 'a'"
                        :href="section.url"
                        class="font-brand px-4 text-sm font-semibold uppercase no-underline"
                        :class="
                            [
                                section.text ? '' : 'hidden xs:block',
                                isActiveTab(section) ? 'text-white' : 'text-gray-500 hover:text-white',
                            ].join(' ')
                        "
                        @click="onSectionClick($event, section)"
                    >
                        {{ section.text ? section.text : "&nbsp;" }}
                    </component>
                    <MenuDropdown
                        v-if="section.dropdown"
                        :menu="section.dropdown"
                        :in-drop-down="false"
                    ></MenuDropdown>
                </div>
                <div
                    v-if="!isObjectEmpty(props.sla)"
                    class="mb-3 inline-flex w-full flex-col"
                >
                    <div class="font-brand px-4 text-sm font-semibold uppercase text-gray-500 no-underline">
                        {{ props.sla.columns[0].header }}
                    </div>
                    <MenuDropdown
                        :menu="slaWithoutHeader"
                        :in-drop-down="false"
                    ></MenuDropdown>
                </div>
            </div>
        </div>
    </div>
</template>
