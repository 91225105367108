<script lang="ts" setup>
const props = withDefaults(
    defineProps<{
        tab?: {
            text?: string;
            url?: string;
        };
        isActive: boolean;
        isOpen: boolean;
    }>(),
    {
        tab: () => {
            return {};
        },
    },
);

function onTabClick(e) {
    if (!props.isActive) {
        e.stopPropagation();
    }
}
</script>
<template>
    <div class="flex flex-shrink-0 flex-grow items-stretch">
        <component
            :is="props.isActive ? 'strong' : 'a'"
            :href="props.tab.url"
            class="before:bg-brand-400 flex flex-shrink-0 flex-grow items-center justify-center px-2.5 py-4 font-bold uppercase tracking-tight no-underline antialiased before:absolute before:left-2 before:right-2 before:top-0 before:hidden before:h-1 before:scale-x-0 before:transform before:rounded-b before:transition-transform before:duration-150 before:ease-in group-hover:text-white group-hover:before:scale-100"
            :class="[props.isOpen ? 'text-white' : 'text-gray-400', props.isActive && 'text-white'].join(' ')"
            @click="onTabClick($event)"
        >
            {{ props.tab.text }}
        </component>
    </div>
</template>
