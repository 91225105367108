<script lang="ts" setup>
import MenuDropdown from '@app/components/parts/topBar/menuDropdown.vue';
import Dropdown from '@ui/components/dropdown.vue';
import Icon from '@ui/components/icon.vue';
import { ref } from 'vue';

const props = withDefaults(defineProps<{ menu: object; isMobile: boolean }>(), {
    menu: () => {
        return {};
    },
});
const emit = defineEmits(['show']);
const isOpen = ref(false);
function updateOpen(value) {
    if (value === true) {
        emit('show');
    }
    isOpen.value = value;
}
</script>
<template>
    <Dropdown
        :is-open="isOpen"
        :distance="props.isMobile ? 18 : 20"
        theme="dark"
        placement="top-end"
        :arrow="false"
        @update:is-open="
            (value) => {
                updateOpen(value);
            }
        "
    >
        <div
            class="hover:text-brand-400 flex h-10 w-10 items-center justify-center rounded-full hover:bg-gray-900"
            :class="isOpen ? 'bg-gray-900 text-brand-400' : 'text-gray-400'"
        >
            <span
                class="flex h-5 w-5 transform items-center justify-center transition-transform duration-150 ease-out"
                :class="isOpen && 'rotate-180'"
            >
                <Icon
                    icon="far fa-gear"
                    class="h-5 w-5 text-current"
                ></Icon>
            </span>
        </div>

        <template #content>
            <MenuDropdown
                :menu="props.menu"
                :is-open="isOpen"
            ></MenuDropdown>
        </template>
    </Dropdown>
</template>
