import { createApp } from 'vue';
import Topbar from '@app/components/parts/topBar.vue';
import useI18n from '@/js/utils/I18n';
import { getTranslations } from '@/js/utils/I18n/getTranslations';
import Notifications from '@kyvg/vue3-notification';

const translations = {
    search: {
        placeholder: 'nav.search.empty',
        resultAnswer: 'nav.search.result.answer',
        resultCandidate: 'nav.search.result.candidate',
        resultDateAdd: 'nav.search.result.date_add',
    },
};

const has = (): boolean => {
    return document.querySelectorAll('[data-top-bar]').length > 0;
};

const start = async () => {
    const t = await getTranslations(translations);
    const elements = document.querySelectorAll<HTMLElement>('[data-top-bar]');
    for (const el of Array.from(elements)) {
        const props = JSON.parse(el.dataset.props);
        createApp(Topbar, {
            menu: props.menu,
            search: props.search,
            sla: props.sla,
            settings: props.settings,
            notifications: props.notifications,
            lang: t,
            user: props.user,
            admin: props.admin,
            isDev: props.isDev,
            systemMessages: props.systemMessages,
        })
            .use(await useI18n())
            .use(Notifications)
            .mount(el);
    }
};
export const init = () => {
    if (has()) {
        start();
    }
};
